const { add } = require("lodash");

$(document).on('click', '#menu-profile-avatar', ToggleMenu);
$(document).on('click', '#end-session-option', closeSession);
$(document).on('click', '.responsive-nav-list-open', openEmergentMenu);
$(document).on('click', '#nav-opt-login', openLoginContainer);
$(document).on('click', '#login-container #login-close-button', closeLoginContainer);
$(document).on('click', '#login-container #login-button', loginUser);
$(document).on('click', '#shopping-cart-badge-container .shopping-cart-list-quantity .fa-chevron-down', decreaseProductQuantity);
$(document).on('click', '#shopping-cart-badge-container .shopping-cart-list-quantity .fa-chevron-up', increaseProductQuantity);
$(document).on('change', '#shopping-cart-badge-container .shopping-cart-list-quantity .product-quantity-input', updateProdductQuantity);
$(document).on('click', '#shopping-cart-badge-container .shopping-cart-list-item .product-remove-button', removeProductFromShoppingCart);
$(document).on('click', '#bag_img', goToShoppingCart);
$(document).on('click', '#responsive-close-opt', closeResponsiveEmergentMenu);
$(document).on('click', '#burger-menu', openResponsiveEmergentMenu);
$(document).on('click', '#newsletter-button', addNewsletterEmail); 
$(document).on('click', '.emergent-list-category', toggleCategoryMenuListItems);
$(document).on('change', '#nav-search-input', function(){
    let searchValue = $('#nav-search-input').val();
    if(searchValue == '') return;
    //redirect to search page
    location.href = '/Search/'+searchValue;
});
var emergentMenuFlag = false;
var categoriesArray = [];
var brandsArray = [];
var openShoppingCartListFlag = false;
var windowWidth = $(window).width();

$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
    $('.nav-list-open').hover(openEmergentMenu, closeEmergentMenu);
    $('#emergent-menu').hover(EmergentMenuHover, closeEmergentMenu);
    $('#shopping-cart-badge-container').hover(openShoppingCartList, closeShoppingCartList);
    $('#newsletter-input').keypress(function (e) {
        if (e.which == 13) {
            addNewsletterEmail();
        }
    });
    // GetCurrentUser();
    GetMenuOptions();
    getCompanySocialMedia();
    getLegalDocs();
    getCompanyInfo();
});
function GetMenuOptions(){
    /*let menuOptions = localStorage.getItem("menuOptions");
    if(menuOptions === null){*/
        var DataSend = {
            current_url: location.pathname
        };
        PostMethodFunction('/Admin/Menu/Get', DataSend, null, function(response){localStorage.setItem("menuOptions", JSON.stringify(response));ShowMenuOptions(response);}, null);
    /*}else{
        ShowMenuOptions(JSON.parse(menuOptions));
    }*/
    
}
function ShowMenuOptions(response){
    var options = response.menus;
    var AppenedContent = '';
    $.each(options, function(index, value){
        AppenedContent += '<li class="menu-items-list-item d-flex list-group-item'+(value.active?' active':'')+'" title="'+value.name+'" onclick="location.href=\''+value.url+'\'">';
            AppenedContent += '<div class="text-center menu-items-list-icon align-self-center">';
                AppenedContent += '<i class="image-menu-icon fa '+value.icon+'" alt=""></i>';
            AppenedContent += '</div>';
            AppenedContent += '<div class="d-none d-md-inline-flex menu-items-list-text align-self-center">';
                AppenedContent += '<p class="text-menu text-truncate m-0 my-auto">'+value.name+'</p>';
            AppenedContent += '</div>';
        AppenedContent += '</li>';
    });
    AppenedContent += '<li id="end-session-option" class="menu-items-list-item list-group-item" title="CERRAR SESIÓN">';
        AppenedContent += '<div class="menu-items-list-icon">';
            AppenedContent += '<i class="fa-solid fa-xl fa-arrow-right-from-bracket"></i>';
        AppenedContent += '</div>';
        AppenedContent += '<div class="d-none d-md-inline-flex menu-items-list-text">';
            AppenedContent += '<p class="text-menu text-truncate m-0 my-auto">Cerrar sesión</p>';
        AppenedContent += '</div>';
    AppenedContent += '</li>';
    $('#menu-items-list').empty().append(AppenedContent);
    $(".menu-items-list-item").hover(function () {
        if(!$(this).hasClass('active')){
            $(this).addClass('menu-items-list-hover');
            let image = $(this).find('.image-menu-icon');
            image.attr('src', image.attr('enable-image'));
        }
    }, 
    function () {
        if(!$(this).hasClass('active')){
            $(this).removeClass('menu-items-list-hover');
            let image = $(this).find('.image-menu-icon');
            image.attr('src', image.attr('disable-image'));
        }
    });
}
function ToggleMenu(){
    $('#menu-container').css('display', 'none');
    setTimeout(function(){
        if($('#menu-container').hasClass('menu-container-small')){
            $('#menu-container').removeClass('menu-container-small').addClass('menu-container-large');
        }else{
            $('#menu-container').removeClass('menu-container-large').addClass('menu-container-small');
        }
        $('#menu-container').css('display', 'block');
    }, 10);
}
function closeResponsiveEmergentMenu(){
    $('#responsive-menu').animate({
        left: '-100%'
    }, 500, function(){
        $('#responsive-menu').css('display', 'none');
    });
}
function openResponsiveEmergentMenu(){
    console.log('openResponsiveEmergentMenu');
    $('#responsive-menu').css('display', 'block').css('left', '-100%');
    $('#responsive-menu').animate({
        left: '0%'
    }, 500);
}
function GetCurrentUser(){
    let userData = localStorage.getItem("userData");
    if(userData === null){
        var DataSend = {};
        PostMethodFunction('/Admin/Administrator/GetCurrent', DataSend, null, function(response){localStorage.setItem("userData", JSON.stringify(response.administrator));ShowCurrentUser(response.administrator);}, null);
    }else{
        ShowCurrentUser(JSON.parse(userData));
    }
    
}
function ShowCurrentUser(user){
    $('#current-user-name').text(user.name);
    $('#current-user-identification').text(user.identification);
    $('#menu-profile-avatar').css('background-color', user.color);
}
function closeSession(){
    var DataSend = {};
    PostMethodFunction('/Admin/Administrator/CloseSession', DataSend, null, function(response){location.href="/Admin"}, null);
}
function getCompanySocialMedia(){
  GetMethodFunction('/Home/Company/SocialMedia/Get', null, showCompanySocialMedia, null);
}
function showCompanySocialMedia(result){
    let AppenedContentHeader = '';
    let AppenedContentFooter = '';
    let counter = 0;
    $.each(result.data, function(index, value){
        AppenedContentHeader += '<li class="icon">';
            AppenedContentHeader += '<img onclick="window.open(\''+value.url+'\', \'_blank\')" src="/images/social_media/'+value.icon+'" alt="icono-mail">';
        AppenedContentHeader += '</li>';
        AppenedContentFooter += '<img onclick="window.open(\''+value.url+'\', \'_blank\')" src="/images/social_media/'+value.icon+'" class="footer-sm-img'+(counter==0?' footer-sm-img-first':' footer-sm-img-border')+'" alt="icon">';
        counter++;
    });
    $('#nav-social-media ul').empty().append(AppenedContentHeader);
    $('#footer-social-media').empty().append(AppenedContentFooter);
}
function openEmergentMenu(){
    if(windowWidth >= 768){
        $('#emergent-menu-content-2').empty();
        emergentMenuFlag = false;
        let menu = $('#emergent-menu');
        if(menu.hasClass('nav-list-close')){
            menu.removeClass('nav-list-close').addClass('nav-list-open');
        }
    }
    var value = $(this).attr('value');
    if(value == 'categories'){
        getCategoriesAndSubCategories();
    }else if(value == 'brands'){
        getAllBrands();
    }
}
function closeEmergentMenu(){
    emergentMenuFlag = true;
    setTimeout(() => {
        if(emergentMenuFlag){
            let menu = $('#emergent-menu');
            if(menu.hasClass('nav-list-open')){
                menu.removeClass('nav-list-open').addClass('nav-list-close');
            }
        }
    }, 1000);
}
function EmergentMenuHover(){
    emergentMenuFlag = false;
}
function getCategoriesAndSubCategories(){
    $('#emergent-menu-title').text('CATEGORÍAS');
    if(categoriesArray.length == 0){
        GetMethodFunction('/Home/Category/GetWithSubCategories', null, showCategoriesAndSubCategories, null);
    }else{
        showCategoriesAndSubCategories(categoriesArray);
    }
}
function showCategoriesAndSubCategories(result){
    categoriesArray = result;
    let AppenedContent = '';
    //window width
    if(windowWidth < 768){
        $.each(result.data, function(index, category){
            AppenedContent += '<ul class="emergent-list emergent-categories-list m-0 py-0 px-1 mb-2" value="'+category.id+'">';
                AppenedContent += '<a class="emergent-list-category text-gray-primary m-0 p-0" href="/Category/'+category.url+'">'+category.name+'</a>';
                $.each(category.sub_categories, function(index, subcategory){
                    AppenedContent += '<li class="emergent-list-item emergent-categories-list">';
                        AppenedContent += '<a class="emergent-list-sub-category text-gray-primary" href="/Category/'+category.url+'/'+subcategory.url+'">'+subcategory.name+'</a>';
                    AppenedContent += '</li>';
                });
            AppenedContent += '</ul>';
            $('#responsive-ul-nav-categories').empty().append(AppenedContent);
        });
        
    }else{
        $.each(result.data, function(index, category){
            AppenedContent += '<ul class="emergent-list emergent-categories-list m-0 py-0 px-1 mb-2" value="'+category.id+'">';
                AppenedContent += '<p class="emergent-list-category text-gray-primary m-0 p-0">'+category.name+'<i class="fa fa-chevron-right float-right"></i></p>';
                AppenedContent += '<li class="emergent-list-item emergent-categories-list d-none">';
                        AppenedContent += '<a class="emergent-list-sub-category text-gray-primary" href="/Category/'+category.url+'">Todos</a>';
                AppenedContent += '</li>';
                $.each(category.sub_categories, function(index, subcategory){
                    AppenedContent += '<li class="emergent-list-item emergent-categories-list d-none">';
                        AppenedContent += '<a class="emergent-list-sub-category text-gray-primary" href="/Category/'+category.url+'/'+subcategory.url+'">'+subcategory.name+'</a>';
                    AppenedContent += '</li>';
                });
            AppenedContent += '</ul>';
        });
        $('#emergent-menu-content-1').empty().append(AppenedContent);
        $('.emergent-list').hover(emergetShowCategoryImage, null);
        //append image element
        let image = '<img id="emergent-menu-category-image" src="" alt="">';
        $('#emergent-menu-content-2').empty().append(image);
    }
    
}
function toggleCategoryMenuListItems(){
    let category = $(this).closest('.emergent-categories-list');
    let category_id = category.attr('value');
    let subcategories = category.find('.emergent-list-item');
    if(subcategories.hasClass('d-none')){
        subcategories.removeClass('d-none');
        category.find('.emergent-list-category i').removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }else{
        subcategories.addClass('d-none');
        category.find('.emergent-list-category i').removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }
}
function emergetShowCategoryImage(){
    let cat_id = $(this).attr('value');
    let category = categoriesArray.data.find(x => x.id == cat_id);
    $('#emergent-menu-content-2 #emergent-menu-category-image').attr('src', '/images/category/'+category.image);
}
function getAllBrands(){
    $('#emergent-menu-title').text('MARCAS');
    if(brandsArray.length == 0){
        GetMethodFunction('/Home/Brand/Get', null, showAllBrands, null);
    }else{
        showAllBrands(brandsArray);
    }
    
}
function showAllBrands(result){ 
    brandsArray = result;
    let AppenedContent = '';
    AppenedContent += '<ul class="emergent-list emergent-brand-list m-0 py-0 px-1 mb-2">';
    $.each(result.brands, function(index, brand){
        AppenedContent += '<li class="emergent-brand-list-item" value="'+brand.id+'">';
            AppenedContent += '<a class="emergent-brand-list-item-text text-gray-primary" href="/Brand/'+brand.url+'">'+brand.name+'</a>';
        AppenedContent += '</li>';
    });
    AppenedContent += '</ul>';
    $('#emergent-menu-content-1').empty().append(AppenedContent);
    $('.emergent-brand-list-item').hover(emergetShowBrandImage, null);
    //append image element
    let image = '<img id="emergent-menu-brand-image" src="" alt="">';
    $('#emergent-menu-content-2').empty().append(image);
}
function emergetShowBrandImage(){
    let brand_id = $(this).attr('value');
    let brand = brandsArray.brands.find(x => x.id == brand_id);
    $('#emergent-menu-content-2 #emergent-menu-brand-image').attr('src', '/'+brand.path);
}
function getLegalDocs(){
    GetMethodFunction('/Home/Company/LegalDoc/Get', null, showLegalDocs, null);
}
function showLegalDocs(result){
    let AppenedContent = '';
    let counter = 0;
    $.each(result.data, function(index, value){
        AppenedContent += '<a href="/Home/Company/LegalDoc/Download/'+value.id+'" download="'+value.name+'" class="legal-doc-link'+(counter==0?'':' mt-1')+' d-block w-100">'+value.name+'</a>';
        counter++;
    });
    $('#footer-legal-container').empty().append(AppenedContent);  
}
function getCompanyInfo(){
  GetMethodFunction('/Home/Company/Info/GetCurrent', null, showCompanyInfo, null);
}
function showCompanyInfo(result){
   $('#footer-address1-content').empty().html(result.data.address.replace(/\n/g, "<br>")).attr('href', 'https://www.google.com/maps/search/?api=1&query='+result.data.lat+','+result.data.lng);
    $('#footer-address2-content').empty().html(result.data.address2.replace(/\n/g, "<br>")).attr('href', 'https://www.google.com/maps/search/?api=1&query='+result.data.lat2+','+result.data.lng2);
}
function openLoginContainer(){
    $('#login-container #identification-login-input').val('');
    $('#login-container #password-login-input').val('');
    $('#login-container').fadeIn(300, function(){
        $('#login-container #identification-login-input').focus();
    });
}
function closeLoginContainer(){
    $('#login-container').fadeOut(300);
}
function loginUser(){
    let identification = $('#login-container #identification-login-input').val();
    let password = $('#login-container #password-login-input').val();
    let flag = true;
    if(identification == ''){
        alertWarning('You must enter your identification');
        flag = false;
    }
    if(password == ''){
        alertWarning('You must enter your password');
        flag = false;
    }
    if(flag){
        $('#login-container #login-button').attr('disabled', true).text( 'Loading...');
        let data = {
            identification: identification,
            password: password
        };
        PostMethodFunction('/Home/User/Login', data, null, loginResponseSuccess, function(){
            $('#login-container #login-button').attr('disabled', false).text( 'Login');
        });
    }
}
function loginResponseSuccess(result){
    $('#login-container #login-button').attr('disabled', false).text( 'Login');
    location.href = '/NewArrivals';
}
function openShoppingCartList(){
    if(!openShoppingCartListFlag && $('#shopping-cart-list .shopping-cart-list-item').length > 0){
        openShoppingCartListFlag = true;
        $('#shopping-cart-list').slideDown(300);
    }
}
function closeShoppingCartList(){
    $('#shopping-cart-list').slideUp(300);
    setTimeout(() => {
        openShoppingCartListFlag = false;
    }, 100);
}
function decreaseProductQuantity(e){
    e.preventDefault();
    e.stopPropagation();
    let container = $(this).closest('.shopping-cart-list-item');
    let quantity_container = container.find('.product-quantity-input');
    let quantity = parseInt(quantity_container.val());
    if(quantity > 1){
        quantity--;
        quantity_container.val(quantity);
        SP_updateProductQuantity(container.attr('value'), quantity);
    }
}
function increaseProductQuantity(e){
    e.preventDefault();
    e.stopPropagation();
    let container = $(this).closest('.shopping-cart-list-item');
    let quantity_container = container.find('.product-quantity-input');
    let quantity = parseInt(quantity_container.val());
    quantity++;
    quantity_container.val(quantity);
    SP_updateProductQuantity(container.attr('value'), quantity);
}
function updateProdductQuantity(e){
    e.preventDefault();
    e.stopPropagation();
    let container = $(this).closest('.shopping-cart-list-item');
    let quantity_container = container.find('.product-quantity-input');
    let quantity = parseInt(quantity_container.val());
    if(quantity >= 1){
        SP_updateProductQuantity(container.attr('value'), quantity);
    }
    
}
function removeProductFromShoppingCart(e){
    e.preventDefault();
    e.stopPropagation();
    let shopping_cart_product_id = $(this).attr('value');
    swallMessage(
        '¿Estás seguro de eliminar este producto?'
        , 'Esta acción no se puede deshacer'
        , 'warning'
        , 'Si, eliminar'
        , 'Cancelar'
        , null
        , function(){
            SP_removeProductFromShoppingCart(shopping_cart_product_id);
        }, null);
}
function goToShoppingCart(){
    if(has_user==1){
        location.href='/ShoppingCart';
    }else{
        openLoginContainer();
    }
}
function addNewsletterEmail(){
    let email = $('#newsletter-input').val();
    let flag = true;
    if(email == '' || !validateEmail(email)){
        alertWarning('You must enter your email');
        flag = false;
    }
    if(flag){
        let data = {
            email: email
        };
        PostMethodFunction('/Home/Newsletter/Add', data, null, addNewsletterEmailSuccess, null);
    }
}
function addNewsletterEmailSuccess(result){
    alertSuccess('Email added successfully');
    $('#newsletter-input').val('');
}